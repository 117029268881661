import {CssBaseline} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import {createTheme, ThemeProvider, StyledEngineProvider} from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import {BoxAndWiskers, BoxPlotController, Violin, ViolinChart, ViolinController} from "@sgratzl/chartjs-chart-boxplot";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {
  Chart as ChartJS, BarElement, RadarController, CategoryScale, Legend, LinearScale, LineController, LineElement, PointElement, ScatterController, Title,
  Tooltip, RadialLinearScale
} from "chart.js";
import AnnotationPlugin from "chartjs-plugin-annotation";
import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";

import Modules from "@core/constants/modules";
import Roles from "@core/constants/roles";

import NotFoundPage from "@core/components/404";
import ProtectedRoute from "@core/components/ProtectedRoute";

// Account
import Account from "./Account";

// Admin
import ExternalUser from "./Admin/ExternalUser";
import ExternalUsers from "./Admin/ExternalUsers";
import InviteUsers from "./Admin/InviteUsers";
import Logs from "./Admin/Logs";
import NewUser from "./Admin/NewUser";
import Admin from "./Admin/Overview";
import User from "./Admin/User";
import Users from "./Admin/Users";

// Campaigns
import Campaign from "./Campaigns/Campaign";
import Campaigns from "./Campaigns/Overview";

// SingleMaterialCampaigns
import NewCampaign from "./SingleMaterialCampaigns/CreateNew";
import SingleMaterialCampaign from "./SingleMaterialCampaigns/SingleMaterialCampaign";
import SingleMaterialCampaigns from "./SingleMaterialCampaigns/Overview";

// Certificates
import Archive from "./Certificates/Archive";
import ArchivedCertificate from "./Certificates/ArchivedCertificate";
import Certificate from "./Certificates/Certificate";
import NewCertificate from "./Certificates/CreateNew";
import Certificates from "./Certificates/Overview";

// Companies
import DashboardCompany from "@core/components/DashboardCompany";
import Company from "./Companies/Company";
import NewCompany from "./Companies/CreateNew";
import Companies from "./Companies/Overview";

// Dashboard
import Dashboard from "./Dashboard";

// DataAnalytics
import DataAnalytics from "./DataAnalytics";

// ErrorPage
import ErrorPage from "./ErrorPage";

// Instruments
import Instruments from "./Instruments";

// Login
import Login from "./Login/LoginPage";
import PasswordRecovery from "./Login/PasswordRecovery";
import ProviderLoginHandler from "./Login/ProviderLoginHandler";
import Registration from "./Login/Registration";
import UserSelect from "./Login/UserSelect";
import MobilePasscodeRecovery from "./Login/MobilePasscodeRecovery";

// Logout
import Logout from "./Logout";

// Preferences
import Conditions from "./Preferences/Conditions";
import ProductTypes from "./Preferences/ProductTypes";
import Statements from "./Preferences/Statements";

// Products
import Products from "./Products";

// Projects
import Projects from "./Projects/Overview";
import Project from "./Projects/Project";

// Specifications
import NewSpecification from "./Specifications/CreateNew";
import Specifications from "./Specifications/Overview";
import Specification from "./Specifications/Specification";

// Qcps
import Qcps from "./Qcps";

// Tests
import ImportFromJSON from "./Tests/ImportFromJSON";
import Tests from "./Tests/Overview";
import Test from "./Tests/Test";

// CustomerOrders
import OutgoingCertificate from "./CustomerOrders/Certificate";
import CustomerOrder from "./CustomerOrders/CustomerOrder";
import CustomerOrders from "./CustomerOrders/Overview";

// OrdersToSupplier
import OrderToSupplier from "./OrdersToSupplier/OrderToSupplier";
import OrdersToSupplier from "./OrdersToSupplier/Overview";

// Reports
import ReportCertificate from "./Reports/Certificate";
import Reports from "./Reports/Overview";
import Report from "./Reports/Report";

// MultipleTestSigning
import MultipleTestSigning from "@core/components/MultipleTestSigning";

// ParseFile
import ParseFile from "@core/components/ParseFile";

// Metrics
import Metrics from "./Metrics";

import WS from "@core/api/socketConnection";
import userService from "@core/api/user-service";
import themeSettings from "./theme-settings";
import {compose} from "ramda";

const queryClient = new QueryClient();
const theme = createTheme(themeSettings);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BoxPlotController,
  BoxAndWiskers,
  Violin,
  ViolinChart,
  ViolinController,
  Title,
  Tooltip,
  Legend,
  RadarController,
  AnnotationPlugin,
  PointElement,
  LineElement,
  BarElement,
  ScatterController,
  LineController,
  RadialLinearScale
);

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      redirectToTest: ""
    };

  }

  redirectToForm = (type) => {
    if (type) {
      this.setState({redirectToTest: <Redirect to={`/tests/${type}`} />});
    }
  };

  content() {
    return (
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              {this.state.redirectToTest}
              <CssBaseline />
              <Switch>

                {/* --- No restriction routes ---*/}
                <Route exact path='/error' children={<ErrorPage />} />
                <Route exact path='/login' children={<Login />}/>
                <Route exact path='/login/provider' children={<ProviderLoginHandler />} />
                <Route exact path='/login/selectUser' children={<UserSelect />} />
                <Route exact path='/passwordRecovery' children={<PasswordRecovery />} />
                <Route exact path="/invite" children={<Registration />} />
                <Route exact path="/mobilePasscodeRecovery" children={<MobilePasscodeRecovery />}/>

                {/* --- All modules routes ---*/}
                <ProtectedRoute exact path='/' children={<Dashboard />} />
                <ProtectedRoute exact path='/account' children={<Account />} />
                <ProtectedRoute exact path='/logout' children={<Logout logout={this.logout.bind(this)} />} />

                {/* --- Producer's and Basic routes ---*/}
                <ProtectedRoute exact path='/transfers' children={<CustomerOrders />} modules={[Modules.PRODUCER, Modules.BASIC]} />
                <ProtectedRoute exact path='/transfers/:id' children={<CustomerOrder />} modules={[Modules.PRODUCER, Modules.BASIC]} />

                {/* --- Producer's, Basic and End Owner's routes ---*/}
                <ProtectedRoute exact path='/certificates' children={<Certificates />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/certificates/archive' children={<Archive />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/certificates/archive/:id' children={<ArchivedCertificate />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/certificates/create' children={<NewCertificate />} modules={[Modules.PRODUCER, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/certificates/testParsing' children={<ParseFile />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/certificates/:id' children={<Certificate />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />

                <ProtectedRoute exact path='/preferences' children={<ProductTypes />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path="/preferences/conditions" children={<Conditions />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path="/preferences/statements" children={<Statements />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />

                <ProtectedRoute exact path='/specifications' children={<Specifications />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/specifications/create' children={<NewSpecification />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/specifications/:id' children={<Specification />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />

                <ProtectedRoute exact path='/qcps' children={<Qcps />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />

                <ProtectedRoute exact path='/transfers/:id/certificate/:certificateId' children={<OutgoingCertificate />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />

                <ProtectedRoute exact path='/orders' children={<OrdersToSupplier />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/orders/:id' children={<OrderToSupplier />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.END_OWNER]} />

                <ProtectedRoute exact path='/projects/:id' children={<Project />} modules={[Modules.END_OWNER, Modules.BASIC, Modules.PRODUCER]} />
                <ProtectedRoute exact path='/projects' children={<Projects />} modules={[Modules.END_OWNER, Modules.BASIC, Modules.PRODUCER]} />

                {/* --- Producer's and Lab's routes ---*/}
                <ProtectedRoute exact path='/tests/multiSigning' children={<MultipleTestSigning />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.LAB_INTERNAL, Modules.END_OWNER]} />
                <ProtectedRoute exact path='/tests' children={<Tests />} modules={[Modules.PRODUCER, Modules.BASIC, Modules.LAB_INTERNAL, Modules.END_OWNER]} />

                {/* --- Lab's routes ---*/}
                <ProtectedRoute exact path='/tests/testParsing' children={<ParseFile />} modules={[Modules.LAB_INTERNAL]} />
                <ProtectedRoute exact path='/tests/importFromJSON' children={<ImportFromJSON />} modules={[Modules.LAB_INTERNAL]} />
                <ProtectedRoute exact path='/tests/:id' children={<Test />} modules={[Modules.LAB_INTERNAL]} />
                <ProtectedRoute exact path='/instruments/' children={<Instruments />} modules={[Modules.LAB_INTERNAL]} />

                {/* --- End owner's routes ---*/}
                <ProtectedRoute exact path='/dataAnalytics' children={<DataAnalytics />} modules={[Modules.END_OWNER]}/>

                {/* --- Witness's routes ---*/}
                <ProtectedRoute exact path='/witness/multiSigning' children={<MultipleTestSigning />} modules={[Modules.WITNESS]} />
                <ProtectedRoute exact path='/witness/:id/certificate' children={<ReportCertificate />} modules={[Modules.WITNESS]} />
                <ProtectedRoute exact path='/witness/:id' children={<Report />} modules={[Modules.WITNESS]} />
                <ProtectedRoute exact path='/witness' children={<Reports />} modules={[Modules.WITNESS]} />

                {/* --- Producer's, End owner's and Witness's routes ---*/}
                <ProtectedRoute exact path='/campaigns' children={<Campaigns />} modules={[Modules.PRODUCER, Modules.END_OWNER, Modules.WITNESS]} />
                <ProtectedRoute exact path={["/campaigns/:id", "/campaigns/:type/:id"]} children={<Campaign />} modules={[Modules.PRODUCER, Modules.END_OWNER, Modules.WITNESS]} />

                <ProtectedRoute exact path='/single-material-campaigns' children={<SingleMaterialCampaigns />} modules={[Modules.PRODUCER, Modules.END_OWNER, Modules.WITNESS]} />
                <ProtectedRoute exact path="/single-material-campaigns/create" children={<NewCampaign />} modules={[Modules.PRODUCER, Modules.END_OWNER]} />
                <ProtectedRoute exact path="/single-material-campaigns/create/:id" children={<NewCampaign />} modules={[Modules.PRODUCER, Modules.END_OWNER]} />
                <ProtectedRoute exact path="/single-material-campaigns/:id" children={<SingleMaterialCampaign />} modules={[Modules.PRODUCER, Modules.END_OWNER, Modules.WITNESS]} />

                {/* --- Producer's and End owner's routes ---*/}
                <ProtectedRoute exact path='/products' children={<Products />} modules={[Modules.PRODUCER, Modules.END_OWNER]} />

                {/* --- Company's admin routes ---*/}
                <ProtectedRoute exact path='/admin' children={<Admin />} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/admin/users' children={<Users />} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]}/>
                <ProtectedRoute exact path='/admin/users/new' children={<NewUser />} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/admin/users/invite' children={<InviteUsers />} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/admin/users/:id' children={<User />} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/admin/external-users' children={<ExternalUsers />} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/admin/external-users/:id' children={<ExternalUser />} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />

                {/* --- Super admin routes ---*/}
                <ProtectedRoute exact path="/metrics" children={<Metrics />} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/admin/logs' children={<Logs />} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/companies' children={<Companies />} modules={[Modules.SUPER_ADMIN]} />
                <ProtectedRoute exact path='/companies/create' children={<NewCompany />} modules={[Modules.SUPER_ADMIN]} />
                <ProtectedRoute exact path='/companies/:id' children={<Company />} modules={[Modules.SUPER_ADMIN]} />
                <ProtectedRoute exact path='/companies/:id/users/create' children={<NewUser />} modules={[Modules.SUPER_ADMIN]} />
                <ProtectedRoute exact path='/companies/:id/dashboard' children={<DashboardCompany />} />
                <ProtectedRoute exact path='/users' children={<Users />} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/users/new' children={<NewUser />} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/users/:id' children={<User />} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />

                {/* --- ??? routes ---*/}
                <ProtectedRoute exact path='/users' children={<Users />} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />
                <ProtectedRoute exact path='/users/create' children={<NewUser />} roles={[Roles.SUPER_ADMIN, Roles.ADMIN]} />

                {/* --- Not existing routes ---*/}
                <ProtectedRoute exact path='*' children={<NotFoundPage />} />
              </Switch>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    );
  }

  render() {

    if (this.state.loaded) {
      return (this.content());
    }

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{minHeight: "100vh"}}
      >

        <Grid item xs={3}>
          <CircularProgress />
        </Grid>

      </Grid>
    );

  }

  componentWillUnmount() {
    WS.remove("test:invite");
    window.removeEventListener("storage", this.listenLogout);
  }

  componentDidUpdate() {
    window.addEventListener("storage", this.listenLogout);
  }

  componentDidMount() {
    const {UserStore} = this.props;
    const token = localStorage.getItem("accessToken");

    if (!token) {
      this.setState({loaded: true});

      return;
    }

    userService.getUserInfo()
      .then(function (user) {
        // Connect WS

        if (user._id) {
          WS.connect(user);
          UserStore.setUser(user);
        }

        WS.listen("test:invite", (data) => {
          this.props.NotificationStore.showInfo(`${data.name} has requested you to perform a ${data.type}!`, {
            button: {
              icon: EditIcon
            },
            callback: this.redirectToForm.bind(null, data.testID)
          });

        });
        this.setState({loaded: true});
      }.bind(this))
      .catch((error) => {

        if (error) {
          this.setState({loaded: true});
        }
      });
  }

  listenLogout(e) {
    const wasRemoved = e.oldValue && !e.newValue;

    if(e.key !== "accessToken" || !wasRemoved) return;

    window.location.reload();
  }
  logout() {
    document.title = "SteelTrace - Certificate Manager";
    this.setState({loaded: true});
  }
}

export default compose(
  inject("NotificationStore", "UserStore"),
  observer
)(App);
