import React, {useCallback, useContext, useEffect} from "react";
import {Field, Form, useFormikContext} from "formik";
import TextField from "@core/components/FormikTextField";
import {AvatarUploader} from "@core/components/Uploaders";
import {Grid, Button, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "../styles";
import Locations from "./Locations";
import Partners from "./Partners";
import Approvals from "./Approvals";
import Modules from "./Modules";
import Features from "./Features";
import {equals} from "ramda";
import {ConfirmationOnUrlChangeContext} from "@core/components/ConfirmationOnUrlChange/context";
import {SHOULD_BLOCK_DEFAULT} from "@core/constants/shouldBlockDefault";

const CompanyForm = ({header, allowSubmit, touched, modulesArr, isNew, classes, values, disabled, isValid, handleSubmit, formik, onSubmit}) => {
  const {initialValues, setFieldValue} = useFormikContext();

  const {setShouldBlock} = useContext(ConfirmationOnUrlChangeContext);

  const customBlocked = useCallback(() => !equals(values, initialValues) && !formik?.isSubmitting, [values,initialValues,formik?.isSubmitting]);

  useEffect(() => {
    setShouldBlock(() => customBlocked);

  }, [customBlocked]);

  useEffect(() => {
    return () => setShouldBlock(() => SHOULD_BLOCK_DEFAULT);
  }, []);

  useEffect(() => {
    const isSubform = Boolean(formik);

    if (!isSubform) return;

    const {setFieldValue, name, setFieldTouched} = formik;

    setFieldTouched(name, touched[name]);
    setFieldValue(name, values, true);

  }, [values]);

  const changeLogo = async (logo) => {
    if(!isNew) await onSubmit({...initialValues, logo});

    setFieldValue("logo", logo);
  };
  
  const getlogoPath = () => {
    let returnPath = "";

    if(values.logo){
      returnPath = values.logo.file.dir+values.logo.file.name;
    }

    return returnPath;
  };

  return (
    <div className="content padded-container">
      <Typography variant="h4" fontSize="1.8rem" marginBottom={3}>
        {header}
      </Typography>
      <Form>
        <Grid container className={classes.mb25}>
          <Grid item xs={8}>
            <Grid container className={classes.mb25} spacing={4}>
              <Grid item xs={6}>
                <TextField
                  disabled={disabled}
                  name="name"
                  label="Name"
                  required
                />
              </Grid>
            </Grid>
            <Grid container className={classes.mb25} spacing={4}>
              <Grid item xs={6}>
                <TextField
                  disabled={disabled}
                  label='Country'
                  name='country'
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={disabled}
                  label='City'
                  name='city'
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={disabled}
                  label='Street'
                  name='street'
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={disabled}
                  label='House number'
                  name='house'
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={disabled}
                  label='Postal code'
                  name='postalCode'
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={disabled}
                  label='Phone'
                  name='phone'
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={disabled}
                  label='Plant description'
                  name='plantDescription'
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={disabled}
                  label='Fax'
                  name='fax'
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={disabled}
                  label='Mail'
                  name='email'
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={disabled}
                  label='Website'
                  name='website'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.btnContainer}>
            <Field name="logo" >
              {({field, form}) => {
                return (
                  <AvatarUploader
                    justifyContent="center"
                    disabled={disabled}
                    className={classes.avatarUpload}
                    preloadText="drop logo file here"
                    buttonText="Upload company logo"
                    file={getlogoPath()}
                    fileId={field.value?._id}
                    handleUploadedFile={({_id}) => {
                      const logo = _id;
                      changeLogo(logo);
                    }}
                    handleResetPreview={() => changeLogo(null)}
                    onRemove={()=>{
                      form.setFieldValue("logo");
                      field.value = null;
                    }}
                  />
                );
              }}
            </Field>
          </Grid>
        </Grid>
        <Locations
          disabled={disabled}
          onSubmit={onSubmit}
        />
        <Approvals
          disabled={disabled}
          onSubmit={onSubmit}
        />
        <Partners
          disabled={disabled}
          onSubmit={onSubmit}
        />
        <Modules
          disabled={disabled}
          isNew={isNew}
          modulesArr={modulesArr}
        />
        <Features />
        <div className={classes.submitContainer}>
          {allowSubmit && !disabled && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={!isValid}
              onClick={handleSubmit}
            >
              {values.name !== "" ? "Save" : "Create"}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

CompanyForm.defaultProps = {
  allowSubmit: true
};

export default withStyles(CompanyForm,styles);
